<template>
  <b-modal centered modal-class="save_brand_model"
           id="modal-create-utm-v2" hide-footer
           no-close-on-backdrop
           hide-header>
    <DiscardModel modelTitle="Discard Changes?" modelDescription="Do you want to discard changes? This process can not be undone." @click="discardChanges()"></DiscardModel>
    <div class="w-full h-full">
      <div @click="$bvModal.show('modal-discard-v2')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
        </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="30" viewBox="0 0 46 30" fill="none">
          <path d="M10.5 16.028V11H12.552V16.04C12.552 17.036 13.044 17.612 14.016 17.612C14.988 17.612 15.504 17.036 15.504 16.04V11H17.556V16.028C17.556 18.356 15.924 19.508 13.98 19.508C12.024 19.508 10.5 18.356 10.5 16.028Z" fill="#2560D7"/>
          <path d="M18.5409 12.644V11H25.0569V12.644H22.8249V19.424H20.7729V12.644H18.5409Z" fill="#2560D7"/>
          <path d="M26.0868 19.424V11H28.5108L30.8748 16.832L33.2148 11H35.6268V19.424H33.5748V14.372L31.6908 19.424H30.0348L28.1388 14.36V19.424H26.0868Z" fill="#2560D7"/>
          <rect x="2" y="1.5" width="42" height="27" rx="2.5" stroke="#2560D7" stroke-width="3"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[1rem] font-bold leading-5">Add UTM</p>
        <p class="pt-1 text">Please enter details to apply UTMs.</p>
        <div class="w-full">
          <div class="flex pt-[1.25rem] justify-between ">
            <p class="font-poppins text-[0.875rem] leading-[0.938rem] font-bold">Select UTM</p>
            <p class="text-blue-600 text-sm font-normal leading-[0.938rem] hover:cursor-pointer" @click="$store.commit('RESET_SELECTED_UTM'), selectUTM=true">Create New</p>
          </div>
          <div class="manage-dropdown pt-[0.75rem]">
            <b-dropdown
              ref="utm_list_dropdown"
              offset="25, 0"
              class="h-[3.5rem] hide_caret shadow-xs default_style_dropdown bg-[#F4F6FA] !rounded-lg border-none"
              no-caret
              :disabled="getUTMList.length === 0"

            >
              <div class="h-[3.5rem] flex justify-between items-center py-[0.5rem] px-[1rem]" slot="button-content">
                <div class="text-left">
                  <div class="flex items-center justify-start">
                    <span class="font-poppins text-[0.75rem] text-[#757A8A]">UTM</span>
                  </div>
                  <p class="text !text-[#3C4549]">{{ getSelectedUTM.campaign }}</p>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                  <path opacity="0.45" d="M1 1L5 5L9 1" stroke="#757A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>

              <transition name="fade">
                <template  v-if="getUTMList.length > 0">
                  <ul class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-[0.5rem]">
                    <li :key="index" v-for="(utm, index) in getUTMList"
                        @click="$store.commit('CHANGE_SELECTED_UTM', utm), $refs.utm_list_dropdown.hide(true), selectUTM=true"
                        class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[1rem]">
                      {{ utm.campaign }}
                    </li>
                  </ul>
                </template>
              </transition>
            </b-dropdown>
          </div>
          <div v-if="selectUTM">
            <div class="flex justify-between py-[0.75rem] items-center">
              <p class="text-[0.875rem] leading-[0.938rem] font-bold">UTM Parameters</p>
              <div class="cursor-pointer pr-[0.8rem]" @click="selectUTM= false">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
                  </svg>
                </div>
            </div>
            <ul class="flex-col space-y-3 justify-center items-center gap-1">
                <li>
                  <FloatingLabelInput
                    id="medium"
                    v-model="getSelectedUTM.campaign"
                    type="text"
                    label="UTM Campaign*"
                    customClass="h-[3.5rem] bg-[#F4F6FA]"
                    :errorType="validationsUTM.campaign || validationsUTM.campaignLength ? 'danger' : ''"
                  >
                    <template v-slot:error_message>
                      <InputFieldMessage v-if="validationsUTM.campaign" :message="messagesUTM.campaign"></InputFieldMessage>
                      <InputFieldMessage v-else-if="validationsUTM.campaignLength" :message="messagesUTM.campaignLength"></InputFieldMessage>
                    </template>
                  </FloatingLabelInput>
                </li>
                <li>
                  <FloatingLabelInput
                    id="medium"
                    v-model="getSelectedUTM.medium"
                    type="text"
                    label="UTM Medium*"
                    customClass="h-[3.5rem] bg-[#F4F6FA]"
                    :errorType="validationsUTM.medium || validationsUTM.mediumLength ? 'danger' : ''"
                  >
                    <template v-slot:error_message>
                      <InputFieldMessage v-if="validationsUTM.medium" :message="messagesUTM.medium"></InputFieldMessage>
                      <InputFieldMessage v-else-if="validationsUTM.mediumLength" :message="messagesUTM.mediumLength"></InputFieldMessage>
                    </template>
                  </FloatingLabelInput>
                </li>
                <li>
                  <FloatingLabelInput
                    id="Source"
                    v-model="getSelectedUTM.source"
                    type="text"
                    label="UTM Source*"
                    customClass="h-[3.5rem] bg-[#F4F6FA] !rounded-[0.5rem]"
                    :errorType="validationsUTM.source || validationsUTM.sourceLength ? 'danger' : ''"
                  >
                    <template v-slot:error_message>
                      <InputFieldMessage v-if="validationsUTM.source" :message="messagesUTM.source"></InputFieldMessage>
                      <InputFieldMessage v-if="validationsUTM.sourceLength" :message="messagesUTM.sourceLength"></InputFieldMessage>
                    </template>
                  </FloatingLabelInput>
                </li>
                <li>
                  <FloatingLabelInput
                    id="Term"
                    v-model="getSelectedUTM.term"
                    type="text"
                    label="UTM Term"
                    customClass="h-[3.5rem] bg-[#F4F6FA] !rounded-[0.5rem]"
                    :errorType="validationsUTM.termLength ? 'danger' : ''"
                  >
                    <template v-slot:error_message>
                      <InputFieldMessage v-if="validationsUTM.termLength" :message="messagesUTM.termLength"></InputFieldMessage>
                    </template>
                  </FloatingLabelInput>
                </li>
                <li>
                  <FloatingLabelInput
                    id="Content"
                    v-model="getSelectedUTM.content"
                    type="text"
                    label="UTM Content"
                    customClass="h-[3.5rem] bg-[#F4F6FA] !rounded-[0.5rem]"
                    :errorType="validationsUTM.contentLength ? 'danger' : ''"
                  >
                    <template v-slot:error_message>
                      <InputFieldMessage v-if="validationsUTM.contentLength" :message="messagesUTM.contentLength"></InputFieldMessage>
                    </template>
                  </FloatingLabelInput>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="w-full px-10 flex flex-col items-center">
          <div class="pt-10 pb-11 flex gap-x-3 justify-between items-center">
            <Button
              id="login-button"
              type="button"
              class="border border-gray-500 hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="$bvModal.show('modal-discard-v2')"
            >
              <template v-slot:label>Close</template>
            </Button>
            <Button
              id="login-button"
              type="button"
              class="text-white bg-[#2560D7] hover:bg-blue-700"
              buttonClass="btn-lg"
              @click="createAndApply()"
            >
              <template v-slot:label>Apply</template>
            </Button>
          </div>
        </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { utmTypes } from '@/state/modules/mutation-types'

export default {
  props: ['isShowUTMDropdown'],
 data() {
   return {
     selectUTM: false,
     validationsUTM: {
       name: false,
       nameLength: false,
       medium: false,
       mediumLength: false,
       source: false,
       sourceLength: false,
       campaign: false,
       campaignLength: false,
       contentLength: false,
       termLength: false
     },
     messagesUTM: {
       name: 'UTM Name is required.',
       nameLength: 'The name should not be greater than 35 characters.',
       medium: 'Medium Name is required.',
       mediumLength: 'The medium should not be greater than 100 characters.',
       source: 'Source name is required.',
       sourceLength: 'The source should not be greater than 100 characters.',
       campaign: 'Campaign name is required.',
       campaignLength: 'The campaign should not be greater than 100 characters.',
       contentLength: 'The content should not be greater than 2000 characters.',
       termLength: 'The term should not be greater than 2000 characters.'
     }
   }
 },
  async created () {
    await this.fetchUTMList()
  },
  computed: {
    ...mapGetters(['getUTMList', 'getSelectedUTM', 'getCreateUTM']),
  },
  components: {
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    URLInputV2: () => import('@/ui/ui-kit/v2/URLInputV2.vue'),
    InputButton: () => import('@/ui/ui-kit/v2/InputButton.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    DiscardModel: () => import('@/ui/ui-kit/v2/DiscardModel.vue'),
  },
  methods: {
    ...mapActions(['fetchUTMList', 'applyUTM', 'storeUTM']),
    discardChanges() {
      this.resetUTMValidations()
      this.$store.commit('RESET_SELECTED_UTM')
      this.selectUTM = false
      this.$bvModal.hide('modal-create-utm-v2')
    },
    /**
     * validating the UTMS and this method will be call by its parent for validations
     * @returns {this is string[]}
     */
    validateUTMS() {
      this.validationsUTM.medium = this.requiredCheck(this.getSelectedUTM.medium)
      this.validationsUTM.source = this.requiredCheck(this.getSelectedUTM.source)
      this.validationsUTM.campaign = this.requiredCheck(this.getSelectedUTM.campaign)
      return Object.keys(this.validationsUTM).every(k => this.validationsUTM[k] === false)
    },
    async createAndApply () {

      if (this.validateUTMS()) {
        // validate the url first
        let url = await this.$store.dispatch('validateUTMURL', 'link')

        // if url validated then store and apply utm by passing the url
        if (url) {
          let res = await this.storeUTM(this.getSelectedUTM)
          await this.$store.commit(utmTypes.CHANGE_SELECTED_UTM, res)
          setTimeout(() => {
            this.applyUTM('link')
          }, 200)
          this.resetUTMValidations()
        }
        this.$bvModal.hide('modal-create-utm-v2')
      }

    },
    /**
     * resetting the UTM validation and this methode will be call from its parent
     */
    resetUTMValidations() {
      Object.keys(this.validationsUTM).forEach(key => {
        this.validationsUTM[key] = false;
      });
    }
  },
  watch: {
    'getSelectedUTM.campaign' (value) {
      if (value && value.length > 0) {
        this.validationsUTM.campaign = false
        this.validationsUTM.campaignLength = !this.maxLength(value, 100)
      }
    },
    'getSelectedUTM.medium' (value) {
      if (value && value.length > 0) {
        this.validationsUTM.medium = false
        this.validationsUTM.mediumLength = !this.maxLength(value, 100)
      }
    },
    'getSelectedUTM.source' (value) {
      if (value && value.length > 0) {
        this.validationsUTM.source = false
        this.validationsUTM.sourceLength = !this.maxLength(value, 100)
      }
    },
    'getSelectedUTM.term' (value) {
      if (value && value.length > 0) {
        this.validationsUTM.termLength = !this.maxLength(value, 2000)
      }
    },
    'getSelectedUTM.content' (value) {
      if (value && value.length > 0) {
        this.validationsUTM.contentLength = !this.maxLength(value, 2000)
      }
    }
  }
}
</script>
